<template>
    <Navbar />

    <div class="">

        <div class="page-section bg-green-50">
            <div class="container">
                <div class="row my-5 card-blog-row">
                    <div class="relative lg:hidden col-md-6 col-lg-3 py-3 wow fadeInUp">
                        <div class="card-blog">
                            <div class="header">
                                <div class="entry-footer">
                                    <div class="post-author">Welcome to the Projects</div>
                                    <a href="#" class="post-date"></a>
                                </div>
                            </div>
                            <div class="body">
                                <div class="post-title">
                                    <a>
                                        Share My Knowledge
                                        <br />
                                        Research Skills
                                        <br />
                                        Makes More Employable
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Contact />

        <Footer />
    </div>
</template>

<script>

import Navbar from "../components/Navbar";
import Services from "../components/Services";
import Footer from "../components/Footer";
import Contact from '../components/Contact.vue';

export default {
    name: "Project Page",
    data() {
        return {

        };
    },

    props: {
        blog: Array,
    },

    components: { Navbar, Services, Footer, Contact },
};
</script>

<style></style>
