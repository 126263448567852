<template>
  <div class="bg-green-50 hidden lg:flex lg:items-center lg:justify-center">
    <div class="bg-green-50 page-section features">
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-md-6 col-lg-4 py-3 wow fadeInUp">
            <div class="d-flex flex-row">
              <div class="img-fluid mr-3">
                <lord-icon
                  src="https://cdn.lordicon.com/fyuvwkhk.json"
                  trigger="hover"
                  style="width: 150px; height: 150px"></lord-icon>
              </div>
              <div>
                <h>Front End</h>
                <p>
                  I specialize in crafting captivating user interfaces and seamless user experiences.
                </p>
              </div>
            </div>
          </div>

          <div
            
            class="col-md-6 col-lg-4 py-3 wow fadeInUp">
            <div class="d-flex flex-row">
              <div class="">
                <div class="img-fluid mr-3">
                  <lord-icon
                    src="https://cdn.lordicon.com/rvulxkpr.json"
                    trigger="hover"
                    style="width: 150px; height: 150px"></lord-icon>
                </div>
              </div>
              <div>
                <h4>Backend</h4>
                <p>
                  Behind the scenes, I architect robust server-side solutions using technologies like PHP Laravel and Node JS.
                </p>
              </div>
            </div>
          </div>

          <div
           
            class="col-md-6 col-lg-4 py-3 wow fadeInUp">
            <div class="d-flex flex-row">
              <div class="img-fluid mr-3">
                <lord-icon
                  src="https://cdn.lordicon.com/hyymuwkm.json"
                  trigger="hover"
                  style="width: 150px; height: 150px"></lord-icon>
              </div>
              <div>
                <h5>Devops</h5>
                <p>
                  I embrace DevOps principles to streamline development and deployment processes. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
