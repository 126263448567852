<template>
    <nav class="p-3 border-gray-200 rounded bg-green-50 dark:bg-gray-800 dark:border-gray-700"
        style="background-color: beige;">
        <div class="container flex flex-wrap items-center justify-between mx-auto">
            <router-link to="/" class="flex items-center hover:no-underline bg-transparent">
                <img src="" class="h-6 mr-3 sm:h-10" />
                <span class="self-center hover:text-green-600 text-xl font-semibold whitespace-nowrap dark:text-white">
                    Dev Hafij
                </span>
            </router-link>

            <div class="hidden w-full md:block md:w-auto">
                <ul
                    class="flex flex-col mt-4 rounded-lg text-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
                    <li>
                        <router-link to="/"
                            class="flex items-center hover:-mt-1 hover:no-underline p-2 ml-2 text-gray-900 rounded-lg dark:text-white hover:bg-green-100 dark:hover:bg-gray-700"
                            aria-current="page">
                            <svg aria-hidden="true"
                                class="flex-shrink-0 w-6 h-6 text-green-500 transition duration-75 hover:-mt-1 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path clip-rule="evenodd" fill-rule="evenodd"
                                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z">
                                </path>
                            </svg>
                            <span class="flex-1 ml-2 whitespace-nowrap">Home</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/projects"
                            class="flex items-center hover:-mt-1 hover:no-underline p-2 ml-2 text-gray-900 rounded-lg dark:text-white hover:bg-green-100 dark:hover:bg-gray-700"
                            aria-current="page">
                            <svg aria-hidden="true"
                                class="flex-shrink-0 w-6 h-6 text-green-500 transition duration-75 dark:text-gray-400 dark:group-hover:text-white"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2 4.25A2.25 2.25 0 014.25 2h2.5A2.25 2.25 0 019 4.25v2.5A2.25 2.25 0 016.75 9h-2.5A2.25 2.25 0 012 6.75v-2.5zM2 13.25A2.25 2.25 0 014.25 11h2.5A2.25 2.25 0 019 13.25v2.5A2.25 2.25 0 016.75 18h-2.5A2.25 2.25 0 012 15.75v-2.5zM11 4.25A2.25 2.25 0 0113.25 2h2.5A2.25 2.25 0 0118 4.25v2.5A2.25 2.25 0 0115.75 9h-2.5A2.25 2.25 0 0111 6.75v-2.5zM15.25 11.75a.75.75 0 00-1.5 0v2h-2a.75.75 0 000 1.5h2v2a.75.75 0 001.5 0v-2h2a.75.75 0 000-1.5h-2v-2z">
                                </path>
                            </svg>
                            <span class="flex-1 ml-2 whitespace-nowrap">Projects</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/about"
                            class="flex items-center p-2 ml-2 hover:-mt-1 hover:no-underline text-gray-900 rounded-lg dark:text-white hover:bg-green-100 dark:hover:bg-gray-700"
                            aria-current="page">
                            <svg aria-hidden="true"
                                class="flex-shrink-0 w-6 h-6 text-green-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path clip-rule="evenodd" fill-rule="evenodd"
                                    d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z">
                                </path>
                            </svg>
                            <span class="flex-1 ml-2 whitespace-nowrap">About</span>
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link
                        to="/blog"
                        class="flex items-center p-2 ml-2 hover:-mt-1 hover:no-underline text-gray-900 rounded-lg dark:text-white hover:bg-green-100 dark:hover:bg-gray-700"
                        aria-current="page">
                        <svg
                            aria-hidden="true"
                            class="flex-shrink-0 w-6 h-6 text-green-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                            clip-rule="evenodd"
                            fill-rule="evenodd"
                            d="M1 5.25A2.25 2.25 0 013.25 3h13.5A2.25 2.25 0 0119 5.25v9.5A2.25 2.25 0 0116.75 17H3.25A2.25 2.25 0 011 14.75v-9.5zm1.5 5.81v3.69c0 .414.336.75.75.75h13.5a.75.75 0 00.75-.75v-2.69l-2.22-2.219a.75.75 0 00-1.06 0l-1.91 1.909.47.47a.75.75 0 11-1.06 1.06L6.53 8.091a.75.75 0 00-1.06 0l-2.97 2.97zM12 7a1 1 0 11-2 0 1 1 0 012 0z"></path>
                        </svg>
                        <span class="flex-1 ml-2 whitespace-nowrap">Blogs</span>
                        </router-link>
                    </li> -->

                    <li></li>
                    <li></li>

                    <li class="hidden lg:flex items-center border border-gray-500 hover:bg-green-100 rounded-lg px-4 py-1 md:order-2">
                        <router-link to="/contact-us"
                            data-dropdown-toggle="language-dropdown-menu"
                            class="inline-flex items-center justify-center p-2 rounded cursor-pointer hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white">
                            Contact Me
                        </router-link>
                        <!-- <a href="https://www.behance.net/hafijur2584" target="_blank"
                            data-dropdown-toggle="language-dropdown-menu"
                            class="inline-flex items-center justify-center p-2 rounded cursor-pointer hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white">
                            Contact Me
                        </a> -->
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "Navbar",
    data() {
        return {
            active: 0,
        };
    },
    methods: {
        inActive() {
            this.active += 1;
        },
    },
};
</script>

<style>
.fade-nav-enter-active,
.fade-nav-leave-active {
    transition: opacity 0.5s ease;
}

.fade-nav-enter-from,
.fade-nav-leave-to {
    opacity: 0;
}

.vue-school-active-link {
    background-color: rgb(225, 255, 228);
    padding: 5px 12px;
    border-radius: 10px;
    text-decoration: none;
}
</style>
