<template>
    <div class="bg-green-50 page-section pt-5 -mb-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 py-3 wow zoomIn">
                    <div class="img-place text-center hidden lg:flex">
                        <lottie-player src="https://assets2.lottiefiles.com/packages/lf20_qhrndegx.json"
                            background="transparent" speed="1" style="max-width: 500px; max-height: 500px"
                            autoplay></lottie-player>
                    </div>
                </div>

                <div class="col-lg-6 p-4">
                    <h2 class="title-section text-2xl">
                        All About
                        <span class="marked">Me</span>
                    </h2>
                    <div class="divider"></div>

                    <div>
                        <p>
                            Hello! I'm Hafijur Rahman, a dedicated Full Stack Web Developer who loves bringing ideas to life
                            on the web. I enjoy staying up to date in the ever-changing tech world, always eager to learn
                            and create user-friendly web solutions.
                        </p>
                    </div>

                    <div>
                        <p>
                            My journey in web development began with pure curiosity, which quickly grew into a passion.
                            Along the way, I've had the pleasure of working on a wide variety of projects, from online
                            stores to content management systems and everything in between. Each project has taught me
                            something new and shaped me into the developer I am today.
                        </p>
                    </div>

                    <div>
                        <p class="hidden lg:flex">
                            Throughout my career as a Full Stack Web Developer, I've had the opportunity to collaborate with
                            a diverse range of clients and companies, each with their unique challenges and goals. These
                            collaborations have given me valuable experience and insights across different industries and
                            project types.
                        </p>
                    </div>

                    <div class="img-place mb-3"></div>
                    <a href="#" class="btn btn-primary">More Details</a>
                    <a href="#" class="btn btn-outline border ml-2">Success Stories</a>
                </div>
            </div>
        </div>
        <!-- .container -->
    </div>
    <!-- .page-section -->

    <div class="bg-green-50 page-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 p-4 -mt-9 lg:mt-0">
                    <h2 class="title-section text-2xl">
                        My
                        <span class="marked">Skills & Experiences</span>
                    </h2>
                    <div class="divider"></div>
                    <h1 class="font-bold text-xl">My Skills</h1>
                    <p class="mb-4">
                        <strong>Front-end Development:</strong> I specialize in crafting captivating user interfaces and
                        seamless user experiences.
                        My expertise includes HTML, CSS, JavaScript, and popular libraries like Vue.js, jQuery.
                        I believe that the front-end is the face of any web application, and I'm committed to making it
                        engaging and intuitive.
                    </p>
                    <p class="mb-4">
                        <strong>Back-end Development:</strong> Behind the scenes, I architect robust server-side solutions
                        using technologies like PHP Laravel and Node JS.
                        I'm experienced in database design and management with SQL and NoSQL databases, ensuring that data
                        is stored and retrieved efficiently.
                    </p>

                    <p class="mb-4">
                        <strong>DevOps:</strong> I embrace DevOps principles to streamline development and deployment
                        processes.
                        From version control with Git to continuous integration and deployment with tools like Jenkins and
                        Docker,
                        I make sure the development pipeline is smooth and efficient.
                    </p>

                    <h1 class="font-bold text-xl">My Experiences</h1>

                    <p class="mb-4">
                        <strong>Prolific Infotech (American based It Company):</strong> During my tenure as a Full Stack Web
                        Developer in an American-based IT company,
                        I had the privilege of working on a wide range of high-performance web solutions that shaped the
                        digital landscape.
                        Our projects spanned diverse niches, from cutting-edge streaming platforms to user-friendly website
                        builders and efficient shipping platforms
                    </p>
                    <p class="mb-4">
                        <strong>Spondonit Ltd (codecanyon based It company):</strong> For two years, I had the privilege of
                        being part of a dynamic IT company that specialized in developing cutting-edge web
                        solutions. As a Full Stack Web Developer, my role extended beyond mere coding; it was about
                        transforming ideas into tangible, market-ready products.
                    </p>

                    <!-- <router-link to="/blog" class="btn btn-primary">My Blogs</router-link> -->
                    <router-link to="/projects" class="btn btn-outline ml-2">
                        My Projects
                    </router-link>
                </div>
                <div class="col-lg-6 py-3 wow zoomIn">
                    <div class="img-place text-center">
                        <!-- <lottie-player
              src="https://assets2.lottiefiles.com/packages/lf20_u4jjb9bd.json"
              background="transparent"
              speed="0.5"
              style="max-width: 550px; max-height: 550px"
              loop
              autoplay></lottie-player> -->
                        <lottie-player src="./tt.json" background="transparent" speed="0.5"
                            style="max-width: 550px; max-height: 550px" loop autoplay></lottie-player>
                    </div>
                </div>
            </div>
        </div>
        <!-- .container -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            scrollPosition: 0,
        };
    },

    methods: {
        handleScroll(e) {
            this.scrollPosition = e.target.scrollTop;

            if (this.scrollPosition > 100) {
                console.log("UP");
            } else {
                console.log("DOWN");
            }
        },
    },
};
</script>

<style></style>
