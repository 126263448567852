<template>
    <div class="page-section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 py-3 wow fadeInUp">
                    <h2 class="title-section">Contact information</h2>
                    <div class="divider"></div>
                    <p>
                        If you have any questions or if you'd like to hire me, 
                        <br>
                        please don't hesitate to reach out.
                    </p>

                    <ul class="contact-list">
                        <li>
                            <div class="flex items-center">
                                <div class="icon">
                                    <img class="contact_icons" src="http://localhost:8000/svg/map.svg" alt="">
                                </div>
                                <p>Dhaka, Bangladesh</p>
                            </div>
                        </li>
                        <li>
                            <div class="flex items-center">
                                <div class="icon">
                                    <img class="contact_icons" src="http://localhost:8000/svg/mail.svg" alt="">
                                </div>
                                <a href="#">hafijur2584@gmail.com</a>
                            </div>
                        </li>
                        <li>
                            <div class="flex items-center">
                                <div class="icon">
                                    <img class="contact_icons" src="http://localhost:8000/svg/phone.svg" alt="">
                                </div>
                                <a href="#">+8801729975293</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6 py-3 wow fadeInUp">
                    <div class="subhead">Contact Me</div>
                    <h2 class="title-section">Get In Touch</h2>
                    <div class="divider"></div>

                    <div class="mx-auto mt-16 sm:mt-20">
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label for="first-name" class="block text-sm font-semibold leading-6 text-gray-900">Name</label>
                                <div class="mt-2.5">
                                    <input type="text" autocomplete="given-name" v-model="contact.name"
                                        class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    <span class="text-red-600">{{ contactError.name }}</span>
                                </div>
                            </div>
                            <div>
                                <label for="email" class="block text-sm font-semibold leading-6 text-gray-900">Email</label>
                                <div class="mt-2.5">
                                    <input type="email" v-model="contact.email" autocomplete="email"
                                        class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    <span class="text-red-600">{{ contactError.email }}</span>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label for="company"
                                    class="block text-sm font-semibold leading-6 text-gray-900">Subject</label>
                                <div class="mt-2.5">
                                    <input type="text" v-model="contact.subject" autocomplete="organization"
                                        class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    <span class="text-red-600">{{ contactError.subject }}</span>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label for="message"
                                    class="block text-sm font-semibold leading-6 text-gray-900">Message</label>
                                <div class="mt-2.5">
                                    <textarea v-model="contact.message" rows="4"
                                        class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    <span class="text-red-600">{{ contactError.message }}</span>
                                </div>
                            </div>

                            <div class="content">
                                <span class="text-green-600 font-medium">{{ successMsg }}</span>
                            </div>
                        </div>
                        <div class="mt-10">
                            <button @click="sendContat()"
                                class="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Let's
                                talk</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- .container -->
    </div>
</template>

<script>
import Navbar from "./Navbar.vue";
import Footer from "./Footer.vue";

import {ref} from "vue";
import axios from "../services/axios";

export default {
    name: "Contact",
    components: {
        Navbar: Navbar,
        Footer: Footer,
    },
    setup(){
        let contact = ref({
            name:'',
            email: '',
            subject: '',
            message: ''
        });
        let contactError = ref({
            name:'',
            email: '',
            subject: '',
            message: ''
        });

        let successMsg = ref("");

        const sendContat = () => {
            contactError.value.name = '';
            contactError.value.email = '';
            contactError.value.subject = '';
            contactError.value.message = '';
            successMsg.value = '';

            var validation = true;
            if(!contact.value.name){
                contactError.value.name = 'The name field is required.'
                validation = false;
            }
            if(!contact.value.email){
                contactError.value.email = 'The email field is required.'
                validation = false;
            }
            if(!contact.value.subject){
                contactError.value.subject = 'The subject field is required.'
                validation = false;
            }
            if(!contact.value.message){
                contactError.value.message = 'The message field is required.'
                validation = false;
            }

            if(!validation){
                return false;
            }

            axios.post("/contact-us", contact.value)
            .then((res) => {
                if(res.status == 200){
                    contact.value.name = '';
                    contact.value.email = '';
                    contact.value.subject = '';
                    contact.value.message = '';
                    successMsg.value = 'Submitted Successfully!';
                }
            }).catch(err => {
                contactError.value.name = err.response.data.message;
            });

        };

        return {
            contact,
            contactError,
            sendContat,
            successMsg
        };
    }
};
</script>

<style scoped>
    .bg-indigo-600{
        background-color: rgb(5 122 85 / var(--tw-text-opacity));
    }
    .contact_icons{
        max-height: 25px;
        max-width: 25px;
        margin: 0 auto;
        padding-top: 3px;
    }
</style>
