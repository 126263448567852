export default [
  // {
  //   name: "Personal Portfolio",
  //   id: 1,
  //   content:
  //     "Personal Portfolio Built with VueJs, Bootstrap & Firebase with SignUp & Login Pages",
  //   image:
  //     "https://firebasestorage.googleapis.com/v0/b/personal-portfolio-2102f.appspot.com/o/portfolio.png?alt=media&token=a473b7b7-1ca0-4783-a591-ce667cff7c9d",
  //   tech1:
  //     "https://img.shields.io/badge/VueJS-439A97?style=flat&logo=Vue.js&logoColor=white",
  //   tech2:
  //     "https://img.shields.io/badge/Firebase-FFCA28?style=flat&logo=Firebase&logoColor=white",
  //   tech3:
  //     "https://img.shields.io/badge/Bootstrap-7952B3?style=flat&logo=Bootstrap&logoColor=white",

  //   live: "https://personal-portfolio-2102f.web.app/",
  //   code: "https://github.com/anaskhonprime/personal.github.io",
  // }
];
